import * as React from 'react';

const FeaturedTunes = () => (
  <iframe
    src="https://open.spotify.com/embed/playlist/2xcCuDB00fspACRO6Q8VhW"
    width="100%"
    height="380"
    frameBorder="0"
    allowtransparency="true"
    allow="encrypted-media"
  ></iframe>
);

export default FeaturedTunes;
