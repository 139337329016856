import * as React from 'react';
import { useContext } from 'react'
import { StyleSheet, View, ScrollView } from 'react-native';
import VideoPlayer from '../../components/VideoPlayer';
import { Text, Button } from '@ui-kitten/components';

import RelatedVideos from '../../components/RelatedVideos';
import FeaturedProducts from '../../components/FeaturedProducts';
import FeaturedTunes from '../../components/FeaturedTunes';
import Comments from '../../components/Comments';
import { useResponsive } from '../../utils/responsive';
import { AppContext } from '../Context'

const videoJsOptions = {
  autoplay: true,
  controls: true,
  muted: true
};

const VideoPage = ({ related, video, comments, onPostComment }) => {
  const { toggleFavs, user } = useContext(AppContext)
  const { isDesktop } = useResponsive();

  const isFav = () => {
    return user?.favorites.indexOf(video?.id) > -1
  }

  return (
    <ScrollView style={{ backgroundColor: 'white', flex: 1 }}>
      <View style={[styles.page, styles.videoBackground]}>
        <View style={styles.grid}>
          <View style={[styles.gridElement, styles.gridElementLeft]}>
            {!!video?.video && (
              <VideoPlayer
                options={Object.assign({}, videoJsOptions, {
                  sources: [{ src: video?.video, type: 'application/x-mpegURL' }],
                  poster: video?.thumbnail
                })}
              />
            )}

            <Text style={styles.title}>{video?.name}</Text>
            <Text style={styles.length}>32min</Text>

            <Button status="info" style={styles.button} onPress={() => toggleFavs(video?.id)}>
              {isFav() ? 'Remove from Favs' : 'Add to Favs'}
            </Button>
          </View>

          <View style={[styles.gridElement, styles.gridElementRight]}>
            <Text style={styles.moreLikeThis}>More like this</Text>
            <RelatedVideos videos={related?.slice(0, 5)} />
          </View>
        </View>
      </View>

      <View style={[styles.page, styles.main]}>
        <View style={styles.grid}>
          <View style={[styles.gridElement, styles.gridElementLeft]}>
            {isDesktop && (
              <Text style={styles.videoDescription}>{video?.description}</Text>
            )}

            {video?.products.length > 0 && (
              <View style={[styles.featuredProducts]}>
                <Text style={styles.featuredProductsTitle}>
                  Featured products
                </Text>

                <FeaturedProducts products={video?.products} />
              </View>
            )}

            <Text style={styles.commentsTitle}>{comments?.length || 0} Comments</Text>
            <Comments comments={comments} onPostComment={onPostComment} />
          </View>
          <View
            style={[
              styles.gridElement,
              styles.gridElementRight,
              {
                borderWidth: 1,
                borderColor: '#EDF1F7',
                padding: 32,
                paddingTop: 28,
              },
            ]}
          >
            <Text style={{ marginBottom: 30 }}>Featured Tunes</Text>
            <FeaturedTunes />
          </View>
        </View>
      </View>
    </ScrollView>
  );
};

export default VideoPage;

const styles = StyleSheet.create({
  length: {
    color: '#8F9BB3',
    fontSize: 18,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 28,
    marginBottom: 4,
    marginTop: 20,
  },
  main: {
    marginTop: 40,
  },
  moreLikeThis: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 'normal',
    marginBottom: 6,
    letterSpacing: 3,
    color: '#000',
  },
  page: {
    paddingLeft: 100,
    paddingRight: 100,
  },
  videoBackground: {
    paddingTop: 50,
    paddingBottom: 20,
    backgroundColor: '#F7F9FC',
  },
  featuredProducts: {
    paddingTop: 50,
    paddingBottom: 50,
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  gridElement: {
    paddingRight: 20,
    paddingBottom: 20,
  },
  gridElementLeft: {
    width: '65%',
  },
  gridElementRight: {
    width: '35%',
  },
  videoDescription: {
    color: '#000',
    marginTop: 2,
    fontSize: 15,
    lineHeight: 25,
  },
  featuredProductsTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 20,
    color: '#000',
  },
  commentsTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginTop: 40,
    color: '#000',
  },
});
