import * as React from 'react';
import { useContext } from 'react'
import { ScrollView } from 'react-native';
import Playlist from '../components/Playlist';
import FeaturedVideo from '../components/FeaturedVideo';
import { AppContext } from '../components/Context'


function Home() {
  const { organization } = useContext(AppContext)

  return (
    <ScrollView>
      <FeaturedVideo {...organization?.featuredVideo} />
      {organization?.playlists.map((playlist, idx) => (
        <Playlist key={idx} videos={playlist.videos} name={playlist.name} />
      ))}
    </ScrollView>
  );
}

export default Home;
