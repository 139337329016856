// import * as ScreenOrientation from 'expo-screen-orientation';
import { useDimensions } from '@react-native-community/hooks';
import { useState, useEffect } from 'react';

export const useResponsive = () => {
  const [isDesktop, setIsDesktop] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  const { width, height } = useDimensions().window;

  useEffect(() => {
    // @todo: we might want to do something specific for ipad pros since they have huge rez
    if (width > 1024) {
      setIsDesktop(true);
      setIsMobile(false);
      setIsTablet(false);
    } else if (width > 600) {
      setIsDesktop(false);
      setIsMobile(false);
      setIsTablet(true);
    } else {
      setIsDesktop(false);
      setIsMobile(true);
      setIsTablet(false);
    }
  }, [width, height]);

  return { isDesktop, isMobile, isTablet, width, height };
};
