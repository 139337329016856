import * as React from 'react';
import { StyleSheet, View, TouchableOpacity, Image } from 'react-native';
import { Link } from '@react-navigation/native';
import { Text } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/core';

import LiveThumbnail from './LiveThumbnail';
import { useResponsive } from '../utils/responsive';


function RelatedVideo({ id, thumbnail, gif, name, description }) {
  const { isDesktop } = useResponsive();
  const navigation = useNavigation();

  if (isDesktop) {
    return (
      <Link to={`/video/${id}`}>
        <View style={styles.gridContainer}>
          <View style={styles.imgClass}>
            <LiveThumbnail thumbnail={thumbnail} gif={gif} />
          </View>

          <View style={styles.txtContainer}>
            <Text
              category="h3"
              style={[styles.videoTitle, { marginBottom: 10 }]}
              numberOfLines={1}
            >
              {name}
            </Text>
            {description && (
              <Text numberOfLines={3} style={styles.videoDescription}>
                {description}
              </Text>
            )}
          </View>
        </View>
      </Link>
    );
  }

  return (
    <TouchableOpacity onPress={() => navigation.navigate('video', { id })}>
      <View>
        <Image
          style={styles.image}
          source={{ uri: thumbnail }}
        />

        <Text category="h4" style={styles.videoTitle} numberOfLines={1}>
          {name}
        </Text>
        <Text style={styles.videoLength} numberOfLines={1}>
          32min
        </Text>
      </View>
    </TouchableOpacity>
  );
}

export default RelatedVideo;

const styles = StyleSheet.create({
  image: {
    height: 200,
    marginBottom: 5,
  },
  gridContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 10,
  },
  imgClass: {
    flexBasis: '50%',
  },
  txtContainer: {
    flexBasis: '50%',
    marginLeft: 12,
  },
  videoTitle: {
    fontSize: 18,
  },
  videoLength: {
    fontSize: 12,
    marginBottom: 20,
    color: '#8F9BB3',
  },
  videoDescription: {
    color: '#8F9BB3',
    fontSize: 13,
    lineHeight: 22,
  },
});
