import * as React from 'react';
import { View, StyleSheet, GestureResponderEvent } from 'react-native';
import { Text } from '../components/Text';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Button, Input } from '@ui-kitten/components';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { Icon } from '@ui-kitten/components';
import fetch from '../utils/fetch'

// Make sure to call `loadStripe` outside of a component’s render to avoid


const Checkout = ({
  plan,
  paymentMethods,
  onSuccess,
  isSubmitting,
  onStart
}) => {
  const elements = useElements();
  const stripe = useStripe();
  const [cardToken, setCardToken] = React.useState<null | string>(null)

  const subscribe = async data => {
    const res = await fetch(`/user/subscription`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    const json = await res.json()
    console.log(json)
  }

  const handleSubmit = async (event: GestureResponderEvent) => {
    let token = cardToken;
    // Block native form submission.
    event.preventDefault();
    onStart();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (!token) {
      // Get a reference to a mounted CardElement. Elements knows how
      // to find your CardElement because there can only ever be one of
      // each type of element.
      const cardElement = elements.getElement(CardElement);

      // Use your card Element with other Stripe.js APIs
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error || !paymentMethod) {
        console.log('[error]', error);
      } else {
        token = paymentMethod.id;
      }
    }

    console.log(token)

    await subscribe({
      plan: plan.id,
      token: token,
    })

    onSuccess();
  };


  return (
    <View style={styles.container}>
      <Text category="s1">Billing Information</Text>

      {paymentMethods.map((pm: any) => {
        const isSelected = cardToken === pm.id;
        return (
          <TouchableOpacity
            style={[
              styles.creditCardContainer,
              isSelected && styles.selectedCreditCard,
            ]}
            key={pm.id}
            onPress={() => setCardToken(pm.id)}
          >
            <Text style={styles.creditCardContent}>{pm.brand}</Text>
            <Text style={styles.creditCardContent}>{pm.last4}</Text>
            <Text>
              {pm.exp_month}/{pm.exp_year}
            </Text>
            <Icon
              name={
                isSelected
                  ? 'checkmark-circle-2-outline'
                  : 'plus-circle-outline'
              }
              size={25}
              style={{ height: 25, width: 25 }}
            />
          </TouchableOpacity>
        );
      })}

      <Text category="s2">Add new Card</Text>

      <View style={styles.formContainer}>
        <Input placeholder="Name" />
        <CardElement />
      </View>

      <Input placeholder="Promo Code" />
      <Button onPress={handleSubmit} disabled={isSubmitting}>
        {(evaProps) => <Text {...evaProps}>Subscribe</Text>}
      </Button>
    </View>
  );
};

export default Checkout;

const styles = StyleSheet.create({
  container: {
    marginVertical: 12,
  },
  creditCardContainer: {
    padding: 4,
    margin: 12,
    flexDirection: 'row',
    height: 24,
    flex: 1,
    borderWidth: 1,
    borderColor: 'grey',
    borderRadius: 5,
  },
  creditCardContent: {
    marginRight: 12,
  },
  selectedCreditCard: {
    borderColor: 'green',
  },
  formContainer: {
    marginVertical: 24,
  },
});
