import * as React from 'react';
import { StyleSheet, View, FlatList } from 'react-native';
import VideoTile from '../VideoTile';
import { Text } from '@ui-kitten/components';


function Playlist({ videos, name }) {
  const renderItem = (video) => {
    return <VideoTile {...video.item} key={video.id} />;
  };

  return (
    <View style={styles.container}>
      <Text style={styles.playlistTitle}>{name}</Text>

      <FlatList
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        data={videos}
        horizontal={true}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
        ListHeaderComponent={() => <View style={styles.header} />}
      />
    </View>
  );
}

export default Playlist;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 2,
  },
  playlistTitle: {
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 12,
    marginTop: 22,
    paddingHorizontal: 12,
  },
  header: {
    width: 12,
  },
});
