import * as React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

const AccountProfile = () => {
  return (
    <View>
      <Text>profile</Text>
    </View>
  );
};

export default AccountProfile;
