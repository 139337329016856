import * as React from 'react'
import { useResponsive } from '../../utils/responsive'
import VideoDesktop from './VideoDesktop'
import VideoMobile from './VideoMobile'

const VideoPage = props => {
  const { isDesktop } = useResponsive()

  if (isDesktop) {
    return <VideoDesktop {...props} />
  }

  if (!isDesktop) {
    return <VideoMobile {...props} />
  }
}

export default VideoPage
