import * as React from 'react';
import {
  Linking,
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
} from 'react-native';
import { Text, Button } from '@ui-kitten/components';


const redirectToPage = (url: string) => Linking.openURL(url);

function FeaturedProducts({ products, limit }) {
  return (
    <View style={{ alignSelf: 'flex-start' }}>
      <View style={styles.featuredProducts}>
        {products.slice(0, limit || 2).map((product) => (
          <View style={styles.product} key={product.id}>
            <TouchableOpacity
              onPress={() => redirectToPage(product.pageUrl)}
              style={{ height: 150, width: 150 }}
            >
              <Image
                style={styles.productImage}
                source={{ uri: product.imageUrl }}
                resizeMode="contain"
              />
            </TouchableOpacity>
            <View style={styles.productContent}>
              <Text style={styles.name}>{product.name}</Text>
              <Text style={styles.description} numberOfLines={3}>
                {product.description}
              </Text>
              <View>
                <Button
                  onPress={() => redirectToPage(product.pageUrl)}
                  status="info"
                  style={styles.button}
                >
                  Shop
                </Button>
              </View>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  name: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 10,
  },
  description: {
    color: '#8F9BB3',
    fontSize: 13,
    lineHeight: 18,
  },
  featuredProducts: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: 20,
    flexWrap: 'wrap',
    //justifyContent: 'flex-start'
  },
  product: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 30,
    //width: '50%',
  },
  productContent: {
    width: 200,
    marginLeft: 20,
  },
  productImage: {
    width: '100%',
    height: '100%',
  },
  button: {
    textTransform: 'uppercase',
    fontSize: 10,
    width: 90,
    marginTop: 15,
  },
});

export default FeaturedProducts;
