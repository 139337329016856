import * as React from 'react';
import { StyleSheet, View, TouchableOpacity } from 'react-native';
import LiveThumbnail from './LiveThumbnail';
import { Text } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/core';


function VideoTile({ id, thumbnail, gif, name, description }) {
  const navigation = useNavigation();

  return (
    <View style={[styles.container]}>
      <TouchableOpacity onPress={() => navigation.navigate('video', { id })}>
        <LiveThumbnail thumbnail={thumbnail} gif={gif} />
        <Text style={styles.title}>{name || ''}</Text>
        <Text style={styles.description}>
          {description?.replace(/^(.{50}[^\s]*).*/, '$1')}
          {description?.length > 50 &&
            <>
              ...
              {' '}
              <Text style={styles.more} to={`/video/${id}`}>
                See more
              </Text>
            </>
          }
        </Text>
      </TouchableOpacity>
    </View>
  );
}

export default VideoTile;

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 0,
    marginBottom: 0,
    height: 230,
    width: 244,
    marginRight: 10,
    overflow: 'hidden',
  },
  description: {
    overflow: 'hidden',
    //textOverflow: 'ellipsis',
    color: '#8F9BB3',
    fontSize: 14,
    lineHeight: 22
  },
  title: {
    marginTop: 14,
    fontSize: 17,
    fontWeight: '600',
    marginBottom: 8
  },
  more: {
    color: '#D2956B',
    fontSize: 14
  },
});
