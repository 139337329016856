import * as React from 'react';
import { useState, useEffect } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native';
import { Text } from '@ui-kitten/components';
import VideoTile from '../components/VideoTile';
import fetch from '../utils/fetch'


function Favorites({ name, videos }) {
  const [favorites, setFavorites] = useState([])

  const getRelated = async () => {
    const res = await fetch(`/user/favorites`)
    const data = await res.json()
    setFavorites(data)
  }

  useEffect(() => {
    getRelated()
  }, [])

  return (
    <ScrollView>
      <View style={styles.container}>
        <View style={styles.content}>
          <Text style={styles.playlistTitle}>{name}</Text>
          {favorites && favorites.map((video, id) => (
            <VideoTile {...video} key={video.id} />
          ))}
        </View>
      </View>
    </ScrollView>
  );
}

export default Favorites;

const styles = StyleSheet.create({
  container: {
    paddingVertical: 2,
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    marginHorizontal: 'auto',
    //paddingHorizontal: 20,
    alignItems: 'center',
  },
  content: {
    width: 1024,
  },
  playlistTitle: {
    fontSize: 20,
    textTransform: 'uppercase',
    letterSpacing: 3,
    marginBottom: 26,
    marginTop: 60,
  },
})
