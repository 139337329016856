import * as React from 'react'
import { useContext } from 'react'
import { StyleSheet, Image, View } from 'react-native';
import { Text, Button } from '@ui-kitten/components';
import { Link } from '@react-navigation/native';
import { AppContext } from './Context'


const FeaturedVideo = ({ id, name, description, thumbnail }) => {
  const { toggleFavs, user } = useContext(AppContext)

  const isFav = () => {
    return user?.favorites?.indexOf(id) > -1
  }

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Image
          style={styles.image}
          source={{ uri: thumbnail }}
          resizeMode="cover"
        />

        <View style={styles.copyContainer}>
          <Text style={styles.featuredLabel}>Featured Video</Text>
          <Text style={styles.title}>{name}</Text>
          <Text style={styles.description}>
            {description?.replace(/^(.{80}[^\s]*).*/, '$1')}
            {description?.length > 80 &&
              <>
                ...
                {' '}
                <Link style={styles.more} to={`/video/${id}`}>
                  See more
                </Link>
              </>
            }

          </Text>

          <View style={styles.buttonContainer}>
            <Link to={`/video/${id}`}>
              <Button status="primary" style={styles.button}>
                Join the Class
              </Button>
            </Link>
            <Button status="info" style={styles.button} onPress={() => toggleFavs(id)}>
              {isFav() ? 'Remove from Favs' : 'Add to Favs'}
            </Button>
          </View>
        </View>
      </View>
    </View>
  );
};

export default FeaturedVideo;

const styles = StyleSheet.create({
  more: {
    color: '#D2956B',
  },
  button: {
    marginRight: 20,
    textTransform: 'uppercase',
    letterSpacing: 1,
    fontSize: 12,
  },
  container: {
    flexGrow: 1,
    justifyContent: 'space-around',
    display: 'flex',
    backgroundColor: '#f7f7f6',
    paddingVertical: 50,
    alignItems: 'center',
  },
  content: {
    width: 1024,
    flexDirection: 'row',
  },
  preview: {
    display: 'flex',
    flex: 1,
  },
  copyContainer: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: 10,
    paddingLeft: 60,
  },
  buttonContainer: {
    marginTop: 6,
    flexDirection: 'row',
  },
  image: {
    height: 300,
    width: '40%',
  },
  featuredLabel: {
    textTransform: 'uppercase',
    fontSize: 16,
    fontWeight: 'normal',
    marginBottom: 20,
    letterSpacing: 3,
    color: '#000',
  },
  title: {
    fontSize: 40,
    fontWeight: '500',
    marginBottom: 16,
    color: '#000',
  },
  description: {
    marginBottom: 20,
    color: '#000',
  },
});
