import * as React from 'react';
import { useState, useEffect, useContext } from 'react'
import { View, StyleSheet } from 'react-native';
import { Link } from '@react-navigation/native';
import { Button, Text } from '@ui-kitten/components';
import PlanTile from '../components/subscription/PlanTile';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Checkout from './Checkout';
import Constants from 'expo-constants';
import { AppContext } from '../components/Context'
import fetch from '../utils/fetch'


const { organizationId, stripePublicToken } = Constants.manifest.extra;
const stripePromise = loadStripe(stripePublicToken);

const SubscriptionPage = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [plans, setPlans] = useState([])
  const [pausing, setPausing] = useState(false)
  const [showPauseResumeMessage, setShowPauseResumeMessage] = useState(false)

  const { user, updateUser } = useContext(AppContext)

  const currentPlan = user?.subscription?.plan?.id
  const paymentMethods = user?.paymentMethods
  console.log('paymentMethods', paymentMethods, currentPlan)
  const subscriptionStatus = user?.subscription?.status

  const [selectedPlanId, setSelectedPlanId] = useState(null)
  const selectedPlan = plans.find(plan => plan.id === selectedPlanId)

  const getPlans = async () => {
    const res = await fetch(`/organization/${organizationId}/plans`)
    const data = await res.json()
    setPlans(data)
  }

  useEffect(() => {
    getPlans()
  }, [])

  const pauseSubscription = async duration => {
    const res = await fetch(`/user/subscription/pause`, {
      method: 'POST',
      body: JSON.stringify({ duration }),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    const data = await res.json()
    console.log(data)
    setPausing(false)
    setShowPauseResumeMessage(true)
    updateUser()
  }

  const resumeSubscription = async () => {
    const res = await fetch(`/user/subscription/resume`, {
      method: 'POST',
    })
    const data = await res.json()
    console.log(data)
    setShowPauseResumeMessage(true)
    updateUser()
  }

  const cancelSubscription = async () => {
    const res = await fetch(`/user/subscription/cancel`, {
      method: 'POST',
    })
    const data = await res.json()
    console.log(data)
    updateUser()
  }

  const onCheckoutSuccess = () => {
    setIsSubmitting(false);
    setSelectedPlanId(null);
    updateUser()
  }

  const handlePlanSelection = (id: string) => {
    if (id !== currentPlan?.id) {
      setSelectedPlanId(id)
    }
  }

  if (!user) {
    return null
  }

  return (
    <Elements stripe={stripePromise}>
      <View style={styles.container}>
        {plans.map((plan, idx) => {
          return (
            <PlanTile
              key={idx}
              plan={plan}
              onPress={() => handlePlanSelection(plan.id)}
              active={plan.id === currentPlan?.id}
              chosen={plan.id === selectedPlanId}
            />
          );
        })}
        {currentPlan && !selectedPlan && (
          <View>
            {subscriptionStatus === 'active' ? (
              <>
                <Button onPress={() => setPausing(true)}>
                  Pause Subscription
                </Button>
                <Button onPress={() => cancelSubscription()}>
                  Cancel Subscription
                </Button>
              </>
            ) : (
                <Button onPress={() => resumeSubscription()}>
                  Resume Subscription
                </Button>
              )}
            {pausing && (
              <>
                <Button onPress={() => pauseSubscription(1)}>
                  1 month
                  </Button>
                <Button onPress={() => pauseSubscription(2)}>
                  2 month
                  </Button>
                <Button onPress={() => pauseSubscription(3)}>
                  3 month
                  </Button>
              </>
            )}
            {showPauseResumeMessage &&
              <Text>If you do not receive a cancel/ pause confirmation email please contact <Link to="mailto:customerservice@thepilatesclass.com">customerservice@thepilatesclass.com</Link></Text>
            }
          </View>
        )}
        {selectedPlan && (
          <Checkout
            plan={selectedPlan}
            isSubmitting={isSubmitting}
            onSuccess={() => onCheckoutSuccess()}
            onStart={() => setIsSubmitting(false)}
            paymentMethods={paymentMethods || []}
          />
        )}
      </View>
    </Elements>
  );
}

export default SubscriptionPage;

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
  },
});
