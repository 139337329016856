import Constants from 'expo-constants'
import { config } from './config'

const { apiUrl } = Constants.manifest.extra;

const tokenFetch = async (path, options: any = {}) => {
  const { token } = config

  const tokenOptions = options
  tokenOptions.credentials = 'include'

  if (!tokenOptions.headers) {
    tokenOptions.headers = {}
  }

  if (token) {
    tokenOptions.headers.Authorization = `Bearer ${token}`
  }

  return fetch(`${apiUrl}${path}`, tokenOptions)
}

export default tokenFetch