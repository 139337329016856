import * as React from 'react';
import { useRef } from 'react';
import { View } from 'react-native';
import { useHover } from 'react-native-web-hooks';


const LiveThumbnail = ({ thumbnail, gif }) => {
  const ref = useRef();
  const isHovered = useHover(ref);

  return (
    <View ref={ref}>
      <img
        style={{ height: '100%', width: '100%' }}
        src={thumbnail}
        alt="thumbnail"
      />
      <img
        style={{
          height: '100%',
          width: '100%',
          opacity: isHovered ? 1 : 0,
          position: 'absolute',
        }}
        src={gif}
        alt="thumbnail"
      />
    </View>
  );
};

export default LiveThumbnail;
