import * as React from 'react';
import { useEffect, useRef } from 'react'
import videojs from 'video.js';
//import Hls from "hls.js";

// Styles
import 'video.js/dist/video-js.css';

//const seekButtons = require('videojs-seek-buttons');
//import seekButtons from 'videojs-seek-buttons'


interface IVideoPlayerProps {
  options: videojs.PlayerOptions;
}

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
  // plugins: {
  //   seekButtons: {
  //     forward: 30,
  //     back: 10
  //   }
  // }
};

const VideoPlayer = (props: IVideoPlayerProps) => {
  const videoNode = useRef<HTMLVideoElement>(null);
  const player = useRef<videojs.Player>();

  // player.seekButtons({
  //   forward: 30,
  //   back: 10
  // });

  const setPlayer = () => {
    if (player.current) {
      if (props?.options?.sources[0].src == player.current.currentSrc()) {
        return
      }
      player.current.src(props?.options?.sources[0])
      return
    }

    player.current = videojs(videoNode.current, {
      ...initialOptions,
      ...props.options,
    }).ready(function () {
      player.current = this
    });
    return () => {
      if (player.current) {
        player.current.dispose();
      }
    };

    // const url = props?.options?.sources[0]?.src
    // if (!url) {
    //   return
    // }

    // const hls = new Hls({
    //   debug: true
    // });

    // hls.loadSource(url);
    // hls.attachMedia(videoNode.current);
    // hls.on(Hls.Events.MANIFEST_PARSED, function () {
    //   videoNode.current.play();
    // });
    // hls.on(Hls.Events.MEDIA_ATTACHED, function () {
    //   videoNode.current.play();
    // });
  }

  useEffect(() => {
    setPlayer()
  }, [props.options.sources])

  return (
    <div>
      <div data-vjs-player>
        <video ref={videoNode} className="video-js" />
      </div>
    </div>
  );
};

// <video ref={videoNode} className="video-js" controls autoplay>
//   <source src={uri} type="application/x-mpegURL" />
// </video>

export default VideoPlayer;
