import * as React from 'react';
import { TouchableOpacity, StyleSheet, View } from 'react-native';
import { Text } from '../Text';
import { intToDollar } from '../../utils/money';


const humanizeInterval = (interval) => {
  switch (interval) {
    case 'yearly':
      return 'year';
    case 'month':
      return 'month';
  }
};

const PlanTile = ({ plan, onPress, active, chosen }) => {
  return (
    <TouchableOpacity onPress={onPress} style={styles.planContainer}>
      <View
        style={[
          styles.topBar,
          chosen && styles.chosenTopBar,
          active && styles.activeTopBar,
        ]}
      ></View>
      <Text category="h5">{plan.name}</Text>
      <Text category="p1">
        {intToDollar(plan.price)}/{humanizeInterval(plan.interval)}
      </Text>
    </TouchableOpacity>
  );
};

export default PlanTile;

const styles = StyleSheet.create({
  planContainer: {
    display: 'flex',
    borderColor: '#EDF1F7',
    borderWidth: 1,
    padding: 20,
    marginBottom: 10,
    borderRadius: 4,
    position: 'relative',
  },
  topBar: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 5,
    width: '100%',
    backgroundColor: '#C5CEE0',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  activeTopBar: {
    backgroundColor: '#D2956B',
  },
  chosenTopBar: {
    backgroundColor: '#222B45',
  },
});
