import * as React from 'react';
import { SafeAreaView } from 'react-native';
import { Layout as LayoutKitten } from '@ui-kitten/components';

const Layout = ({ children }: any) => {
  return (
    <LayoutKitten style={{ flex: 1 }}>
      <SafeAreaView style={{ flex: 1 }}>{children}</SafeAreaView>
    </LayoutKitten>
  );
};

export default Layout;
