import * as React from 'react';
import { StyleSheet, View } from 'react-native';
import VideoTile from '../VideoTile';
import { Text } from '@ui-kitten/components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import './Playlist.css';


function Playlist({ name, videos }) {
  const videosToShow = 4;

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <Text style={styles.playlistTitle}>{name}</Text>

        <Swiper slidesPerView={videosToShow} spaceBetween={60}>
          {videos.map((video, id) => (
            <SwiperSlide key={id} virtualIndex={id}>
              <VideoTile {...video} />
            </SwiperSlide>
          ))}
        </Swiper>
      </View>
    </View>
  );
}

export default Playlist;

const styles = StyleSheet.create({
  swiper: {
    // backgroundColor: 'red'
  },
  slide: {
    // backgroundColor: 'blue'
  },
  container: {
    paddingVertical: 2,
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    width: '100%',
    marginHorizontal: 'auto',
    //paddingHorizontal: 20,
    alignItems: 'center',
  },
  content: {
    width: 1024,
  },
  playlistTitle: {
    fontSize: 20,
    textTransform: 'uppercase',
    letterSpacing: 3,
    marginBottom: 26,
    marginTop: 60,
  },
  header: {
    width: 12,
  },
});
