import * as React from 'react';
import { StyleSheet, View, ScrollView, Dimensions } from 'react-native';
import VideoPlayer from '../../components/VideoPlayer';
import merge from 'lodash/merge';

import { Text } from '../Text';
import RelatedVideos from '../../components/RelatedVideos';
import FeaturedProducts from '../../components/FeaturedProducts';
import FeaturedTunes from '../../components/FeaturedTunes';
import Comments from '../../components/Comments';
import { TabView, SceneMap, TabBar } from 'react-native-tab-view';

const videoJsOptions = {
  autoplay: true,
  controls: true,
};

const VideoPage = ({ video, related, comments, onPostComment }) => {
  const [index, setIndex] = React.useState(0);
  const [routes] = React.useState([
    { key: 'products', title: 'In this video' },
    { key: 'comments', title: 'Comments' },
    { key: 'related', title: 'More like this' },
  ]);

  const ProductsRoute = () => (
    <ScrollView style={styles.scrollPane}>
      {video?.products.length > 0 && (
        <>
          <Text style={styles.swiperTitle}>Featured Products</Text>
          <FeaturedProducts products={video?.products} />
        </>
      )}

      <Text style={[styles.swiperTitle, { marginBottom: 10 }]}>
        Featured Tunes
      </Text>
      <FeaturedTunes />
    </ScrollView>
  );
  const CommentsRoute = () => (
    <ScrollView style={styles.scrollPane}>
      <Text style={styles.swiperTitle}>{comments?.length} Comments</Text>
      <Comments comments={comments} onPostComment={onPostComment} />
    </ScrollView>
  );
  const RelatedRoute = () => (
    <ScrollView style={styles.scrollPane}>
      <RelatedVideos videos={related?.slice(0, 5)} />
    </ScrollView>
  );

  const renderScene = SceneMap({
    products: ProductsRoute,
    comments: CommentsRoute,
    related: RelatedRoute,
  });

  const width = Dimensions.get('window').width;

  const renderTabBar = (props) => (
    <TabBar
      {...props}
      indicatorStyle={{ backgroundColor: '#D2956B' }}
      style={styles.tabBar}
      activeColor="#D2956B"
      inactiveColor="#8F9BB3"
      getLabelText={({ route }) => route.title}
    />
  );

  return (
    <View style={{ backgroundColor: 'white', flex: 1 }}>
      <View style={[styles.videoBackground]}>
        <View>
          {!!video?.video && (
            <VideoPlayer options={merge(videoJsOptions, { sources: [video?.video] })} />
          )}

          <View style={[styles.contentPadding]}>
            <Text style={styles.title}>{video?.name}</Text>
            <Text style={styles.length}>32min</Text>
            <Text numberOfLines={2} style={styles.videoDescription}>
              {video?.description}
            </Text>
          </View>
        </View>
      </View>

      <TabView
        navigationState={{ index, routes }}
        renderScene={renderScene}
        onIndexChange={setIndex}
        initialLayout={{ width }}
        renderTabBar={renderTabBar}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  length: {
    color: '#8F9BB3',
    fontSize: 12,
  },
  tabBar: {
    borderColor: '#E4E9F2',
    borderBottomWidth: 1,
    backgroundColor: 'transparent',
    marginTop: 20,
  },
  title: {
    fontWeight: 'bold',
    fontSize: 18,
    marginBottom: 4,
  },
  videoBackground: {},
  videoDescription: {
    color: '#000',
    marginTop: 2,
    fontSize: 13,
    lineHeight: 22,
  },
  contentPadding: {
    padding: 20,
  },
  swiperTitle: {
    fontSize: 17,
    fontWeight: 'bold',
  },
  scrollPane: {
    flex: 1,
    padding: 20,
    backgroundColor: '#F7F9FC',
  },
});

export default VideoPage;
