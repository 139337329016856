import * as React from 'react';
import { useState, useContext, useEffect } from 'react'
import { View } from 'react-native';
import { Text, Toggle } from '@ui-kitten/components';
import { AppContext } from '../components/Context';
import fetch from '../utils/fetch';


const AccountNotification = () => {
  const { user, updateUser } = useContext(AppContext);

  const [marketingEmail, setMarketingEmail] = useState(false)
  const [newVideoEmail, setNewVideoEmail] = useState(false)
  const [mobileNotification, setMobileNotification] = useState(false)


  const saveNotifications = async (data) => {
    const notifications = Object.assign({}, user.notifications, data)

    const res = await fetch(`/user/notifications`, {
      method: 'POST',
      body: JSON.stringify({ notifications }),
      headers: {
        'Content-Type': 'application/json'
      },
    })

    // updateUser()
  }

  useEffect(() => {
    console.log(user)
    setMarketingEmail(!!user?.notifications?.marketingEmail)
    setNewVideoEmail(!!user?.notifications?.newVideoEmail)
    setMobileNotification(!!user?.notifications?.mobileNotification)
  }, [user])

  return (
    <View>
      <Text>notification preferences</Text>

      <Toggle
        checked={marketingEmail}
        onChange={(value) => { setMarketingEmail(value); saveNotifications({ marketingEmail: value }); }}
      >
        Marketing Emails
      </Toggle>
      <Toggle
        checked={newVideoEmail}
        onChange={(value) => { setNewVideoEmail(value); saveNotifications({ newVideoEmail: value }); }}
      >
        New Video Emails
      </Toggle>
      <Toggle
        checked={mobileNotification}
        onChange={(value) => { setMobileNotification(value); saveNotifications({ mobileNotification: value }); }}
      >
        Mobile Notifications
      </Toggle>
    </View>
  );
};



export default AccountNotification;
