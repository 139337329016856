import * as React from 'react';
import { FlatList, StyleSheet } from 'react-native';
import RelatedVideo from '../components/RelatedVideo';

const RelatedVideos = ({ videos }) => {
  return (
    <FlatList
      data={videos}
      renderItem={({ item, index }) => <RelatedVideo key={item.id} {...item} />}
    />
  );
};

export default RelatedVideos;
