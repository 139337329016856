import * as React from 'react';
import { useState } from 'react'
import { StyleSheet, View } from 'react-native';
//import { Text } from '@ui-kitten/components';
import { Text } from './Text';
import { Input, Button } from '@ui-kitten/components';
import { DateTime } from './DateTime';


const Comments = ({ comments, onPostComment }) => {
  const [comment, setComment] = useState('')

  const postComment = async () => {
    await onPostComment(comment)
    setComment('')
  }

  return (
    <View>
      <Input
        placeholder="Write a comment"
        style={{ marginTop: 24, marginBottom: 14 }}
        onChangeText={event => setComment(event)}
        value={comment}
      />
      <Button style={{ width: 100, alignSelf: 'flex-end' }} status="info" onPress={() => postComment()}>
        SEND
      </Button>

      {comments &&
        comments.map((comment, key) => (
          <View key={key}>
            <View style={styles.top}>
              <Text style={styles.name}>{comment.name}</Text>
              <DateTime style={styles.date} date={comment.date} format="datetime" />
            </View>
            <Text style={styles.body}>{comment.content}</Text>
          </View>
        ))}
    </View>
  );
};

export default Comments;

const styles = StyleSheet.create({
  top: {
    marginTop: 20,
    flexDirection: 'row',
  },
  name: {
    fontWeight: 'bold',
    fontSize: 14,
    lineHeight: 24,
  },
  date: {
    fontSize: 12,
    fontStyle: 'italic',
    lineHeight: 24,
    paddingLeft: 20,
  },
  body: {
    fontSize: 14,
    marginTop: 4,
    color: '#8F9BB3',
    lineHeight: 20,
  },
});
