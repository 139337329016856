import * as React from 'react';
import { useEffect, useState } from 'react'
import { ScrollView, View } from 'react-native';
import FeaturedProducts from '../components/FeaturedProducts';
import Constants from 'expo-constants';
import fetch from '../utils/fetch'


const { organizationId } = Constants.manifest.extra;


function Shop() {
  const [products, setProducts] = useState([])

  const getProducts = async () => {
    const res = await fetch(`/organization/${organizationId}/products`)
    const data = await res.json()
    setProducts(data)
  }

  useEffect(() => {
    getProducts()
  }, [])

  return (
    <ScrollView>
      <View style={{ maxWidth: 1024, margin: '0 auto', alignSelf: 'center' }}>
        <FeaturedProducts products={products} limit={100} />
      </View>
    </ScrollView>
  );
}

export default Shop;
