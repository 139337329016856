import * as React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

const Challenges = () => {
  return (
    <View>
      <Text>Challenges</Text>
    </View>
  );
};

export default Challenges;
