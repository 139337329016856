import * as React from 'react';
import { useState, useEffect } from 'react';
import Video from '../components/Video';
import fetch from '../utils/fetch'


const VideoPage = ({ route }) => {
  const [video, setVideo] = useState(null)
  const [related, setRelated] = useState(null)
  const [comments, setComments] = useState(null)

  const { id } = route.params;

  const getVideo = async () => {
    const res = await fetch(`/video/${id}`)
    const data = await res.json()
    setVideo(data)
  }

  const getRelated = async () => {
    const res = await fetch(`/video/${id}/related`)
    const data = await res.json()
    setRelated(data)
  }

  const getComments = async () => {
    const res = await fetch(`/video/${id}/comments`)
    const data = await res.json()
    setComments(data)
  }

  const onPostComment = async (content) => {
    await fetch(`/video/${id}/comment`, {
      method: 'POST',
      body: JSON.stringify({ content }),
      headers: {
        'Content-Type': 'application/json'
      },
    })
    getComments()
  }

  useEffect(() => {
    getVideo()
    getRelated()
    getComments()
  }, [id])

  return <Video video={video} related={related} comments={comments} onPostComment={onPostComment} />
}

export default VideoPage;
