import * as React from 'react';
import { useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { Icon, Menu, MenuItem, Text } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/core';
import fetch from '../utils/fetch';
import { AppContext } from '../components/Context';

const ForwardIcon = (props) => <Icon {...props} name="arrow-ios-forward" />;

const useMenuState = (initialState = null) => {
  const [selectedIndex, setSelectedIndex] = React.useState(initialState);
  return { selectedIndex, onSelect: setSelectedIndex };
};

const Account = () => {
  const navigation = useNavigation();
  const rightMenuState = useMenuState();
  const { setUser } = useContext(AppContext);


  const doLogout = async () => {
    await fetch(`/user/logout`, {
      credentials: 'include',
    })
    setUser(null)
    navigation.navigate('intro')
  }

  return (
    <View style={{ flex: 1 }}>
      <Menu style={styles.menu} {...rightMenuState}>
        <MenuItem
          title={(props) => <Text style={styles.text}>Profile Info</Text>}
          style={styles.item}
          accessoryRight={ForwardIcon}
          onPress={() => navigation.navigate('profile')}
        />
        <MenuItem
          title={(props) => (
            <Text style={styles.text}>Subscription & Billing</Text>
          )}
          style={styles.item}
          accessoryRight={ForwardIcon}
          onPress={() => navigation.navigate('subscription')}
        />
        <MenuItem
          title={(props) => (
            <Text style={styles.text}>Notification Preferences</Text>
          )}
          style={styles.item}
          accessoryRight={ForwardIcon}
          onPress={() => navigation.navigate('notification')}
        />
        <MenuItem
          title={(props) => <Text style={styles.text}>Help</Text>}
          style={styles.item}
          accessoryRight={ForwardIcon}
          onPress={() => navigation.navigate('help')}
        />
        <MenuItem
          title={(props) => <Text style={styles.text}>Logout</Text>}
          style={styles.item}
          onPress={() => doLogout()}
        />
      </Menu>
    </View>
  );
};

export default Account;

const styles = StyleSheet.create({
  link: {},
  menu: {
    backgroundColor: '#fff',
  },
  item: {},
  text: {
    padding: 10,
  },
});
