import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { NavigationContainer } from '@react-navigation/native';

import { Navigation, linking } from './components/Navigation';
import { useAuth } from './utils/auth';
import Layout from './components/Layout';
import { AppContext } from './components/Context'
import './App.css';

function App() {
  const { user } = useContext(AppContext);

  return (
    <Layout>
      <NavigationContainer linking={linking}>
        <Navigation />
      </NavigationContainer>
    </Layout>
  );
}

export default App;
