import * as React from 'react';
import App from './src/App';
import { ApplicationProvider, IconRegistry } from '@ui-kitten/components';
import * as eva from '@eva-design/eva';
import * as Device from 'expo-device';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import { AppProvider } from './src/components/Context'

import { default as theme } from './src/theme.json';

let mapping;
if (Device.brand) {
  mapping = require('./src/mapping.native.json');
} else {
  mapping = require('./src/mapping.web.json');
}
//import { default as mapping } from './src/mapping.json';

export default () => (
  <>
    <IconRegistry icons={EvaIconsPack} />
    <ApplicationProvider
      {...eva}
      theme={{ ...eva.light, ...theme }}
      customMapping={mapping}
    >
      <AppProvider>
        <App />
      </AppProvider>
    </ApplicationProvider>
  </>
);
