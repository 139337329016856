import * as Device from 'expo-device'
import React, { useState, useEffect, createContext } from 'react'
import AsyncStorage from '@react-native-community/async-storage'
// import { useColorScheme } from 'react-native-appearance'
import { StatusBar } from 'expo-status-bar'
import fetch from '../utils/fetch'
import { config } from '../utils/config'
import Constants from 'expo-constants';


const { organizationId } = Constants.manifest.extra;

// import { Colors } from './Style'
// import api from '../services/api'

export const AppContext = createContext({
  theme: {},
  setTheme: () => { },
  user: null,
  setUser: () => { },
  token: null,
  setToken: () => { },
  updateUser: () => { },
  toggleFavs: (id) => { },
  organization: null
})

export const AppProvider = (props) => {
  const [theme, setTheme] = useState({})
  const [user, setUser] = useState(null)
  const [organization, setOrganization] = useState(null)
  const [ready, setReady] = useState(false)
  const [token, setTokenState] = useState(false)


  // useEffect(() => {
  //   if (!mounted.current) {
  //     return
  //   }
  //   console.log('USER CHANGED AND MOUNTED')

  //   const userJson = JSON.stringify(user)
  //   AsyncStorage.setItem('user', userJson)
  // }, [ user, JSON.stringify(user) ] )


  // const getTheme = async () => {
  //   // const themeStore = await AsyncStorage.getItem('theme')
  //   // setTheme(themeStore || 'light')
  //   setTheme(colorScheme === 'light' ? Colors.light : Colors.dark)
  // }

  const updateUser = async () => {
    const res = await fetch(`/user/me`)
    const json = await res.json()
    if (json?.id) {
      setUser(json)
    }
  }

  const getOrg = async () => {
    const res = await fetch(`/organization/${organizationId}`)
    const json = await res.json()
    if (json?.name) {
      setOrganization(json)
    }
  }

  const getToken = async () => {
    if (Device.brand) {
      const tokenStore = await AsyncStorage.getItem('token')
      const token = JSON.parse(tokenStore)
      if (token) {
        config.token = token
        setTokenState(token)
      }
    }
    await updateUser()
  }

  const setToken = token => {
    AsyncStorage.setItem('token', token)
    config.token = token
    setTokenState(token)
  }

  const getData = async () => {
    if (ready) {
      return
    }

    await Promise.all([
      getToken(),
      getOrg(),
      //      getTheme()
    ])

    setReady(true)
  }

  useEffect(() => {
    getData()
  }, [])

  const addToFavs = async id => {
    await fetch(`/video/${id}/favorite`, {
      method: 'POST'
    })
    return updateUser()
  }

  const removeFromFavs = async id => {
    await fetch(`/video/${id}/favorite`, {
      method: 'DELETE'
    })
    return updateUser()
  }

  const toggleFavs = async id => {
    return user?.favorites?.indexOf(id) > -1 ? removeFromFavs(id) : addToFavs(id)
  }

  const value = {
    theme, setTheme,
    user, setUser,
    token, setToken,
    updateUser,
    addToFavs,
    removeFromFavs,
    toggleFavs,
    organization
  }

  return (
    <AppContext.Provider value={value}>
      { props.children}
    </AppContext.Provider>
  )
}
