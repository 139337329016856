import * as React from 'react';
import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Icon } from '@ui-kitten/components';

import Browse from '../pages/Home';
import LoginPage from '../pages/LoginPage';
import SignupPage from '../pages/SignupPage';
import Favorites from '../pages/Favorites';
import Shop from '../pages/Shop';
import VideoPage from '../pages/VideoPage';
import AccountSubscription from '../pages/AccountSubscription';
import AccountPage from '../pages/AccountPage';
import AccountHelp from '../pages/AccountHelp';
import AccountNotification from '../pages/AccountNotification';
import AccountProfile from '../pages/AccountProfile';
import Challenges from '../pages/Challenges';

import NavBar from './NavBar';
import { useResponsive } from '../utils/responsive';

const Tab = createBottomTabNavigator();
const TabStack = createStackNavigator();
const RootStack = createStackNavigator();
const IntroStack = createStackNavigator();
const AccountStack = createStackNavigator();

const Account = () => (
  <AccountStack.Navigator>
    <AccountStack.Screen
      name="account"
      component={AccountPage}
      options={{
        headerTitle: '',
      }}
    />
    <AccountStack.Screen
      name="subscription"
      component={AccountSubscription}
      options={{
        headerTitle: 'Subscription',
      }}
    />
    <AccountStack.Screen
      name="profile"
      component={AccountProfile}
      options={{
        headerTitle: 'Profile',
      }}
    />
    <AccountStack.Screen
      name="notification"
      component={AccountNotification}
      options={{
        headerTitle: 'Notifications',
      }}
    />
    <AccountStack.Screen
      name="help"
      component={AccountHelp}
      options={{
        headerTitle: 'Help',
      }}
    />

  </AccountStack.Navigator>
);

const Tabs = () => {
  const { isDesktop } = useResponsive();

  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: '#D2956B',
        inactiveTintColor: '#8F9BB3',
        style: { display: isDesktop ? 'none' : 'flex' },
      }}
      screenOptions={({ route }) => ({
        tabBarIcon: ({ focused, color, size }) => {
          let name;

          if (route.name === 'browse') {
            name = focused ? 'home' : 'home-outline';
          } else if (route.name === 'favorites') {
            name = focused ? 'heart' : 'heart-outline';
          } else if (route.name === 'shop') {
            name = focused ? 'shopping-cart' : 'shopping-cart-outline';
          } else if (route.name === 'account') {
            name = focused ? 'person' : 'person-outline';
          } else if (route.name === 'challenges') {
            name = focused ? 'star' : 'star-outline';
          } else {
            return null;
          }

          return (
            <Icon
              name={name}
              color={color}
              size={size}
              style={{ height: size, width: size }}
              fill={color}
            />
          );
        },
      })}
    >
      <Tab.Screen name="browse" component={Browse} />
      <Tab.Screen name="favorites" component={Favorites} />
      <Tab.Screen name="challenges" component={Challenges} />
      <Tab.Screen name="shop" component={Shop} />
      <Tab.Screen name="account" component={Account} />
    </Tab.Navigator>
  );
};

const TabModals = () => {
  return (
    <TabStack.Navigator mode="modal">
      <TabStack.Screen
        name="tabs"
        component={Tabs}
        options={{ headerShown: false }}
      />
      <TabStack.Screen
        name="video"
        component={VideoPage}
        options={{ headerShown: false }}
      />
    </TabStack.Navigator>
  );
};

const RootScreen = () => {
  const { isDesktop } = useResponsive();

  return (
    <RootStack.Navigator>
      <RootStack.Screen
        name="main"
        component={TabModals}
        options={{
          headerShown: isDesktop ? true : false,
          headerTitle: (props) => <NavBar {...props} />,
        }}
      />
      <RootStack.Screen
        name="intro"
        component={IntroScreen}
        options={{
          headerShown: isDesktop ? true : false,
          headerTitle: (props) => <NavBar {...props} />,
        }}
      />
    </RootStack.Navigator>
  );
};

export const IntroScreen = () => (
  <IntroStack.Navigator>
    <IntroStack.Screen
      name="login"
      component={LoginPage}
      options={{ headerShown: false, gestureEnabled: false }}
    />
    <IntroStack.Screen
      name="signup"
      component={SignupPage}
      options={{ headerShown: false, gestureEnabled: false }}
    />
  </IntroStack.Navigator>
);

export const linking = {
  prefixes: {},
  config: {
    screens: {
      main: {
        screens: {
          //NotFound: '*',
          video: 'video/:id',
          tabs: {
            screens: {
              account: {
                screens: {
                  account: 'account',
                  subscription: 'account/subscription',
                  profile: 'account/profile',
                  notification: 'account/notification',
                  help: 'account/help',
                },
              },
              favorites: 'favorites',
              browse: 'browse',
              challenges: 'challenges',
              shop: 'shop',
            },
          },
        },
      },
      intro: {
        screens: {
          login: 'login',
          signup: 'signup',
        }
      }
    },
  },
};

export { RootScreen as Navigation };
