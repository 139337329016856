import * as React from 'react';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

const AccountHelp = () => {
  return (
    <View>
      <Text>help</Text>
    </View>
  );
};

export default AccountHelp;
