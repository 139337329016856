import * as React from 'react';
import { useState, useEffect, useContext } from 'react'
import { View } from 'react-native';
import { Button, Input, Text } from '@ui-kitten/components';
import Constants from 'expo-constants';
import { Link } from '@react-navigation/native';
import { useNavigation } from '@react-navigation/core';
import fetch from '../utils/fetch'
import { AppContext } from '../components/Context';

const { organizationId } = Constants.manifest.extra


const LoginPage = () => {
  const [formComplete, setFormComplete] = useState(false)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [busy, setBusy] = useState(false)

  const { setUser, setToken } = useContext(AppContext);
  const navigation = useNavigation()

  useEffect(() => {
    setFormComplete(!!email && !!password)
  }, [email, password])

  const submitLogin = async () => {
    if (busy) {
      //return
    }
    setBusy(true)
    setError(null)

    const data = {
      email,
      password,
      organization: organizationId
    }

    const res = await fetch(`/user/signup`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
    })

    const json = await res.json()

    if (!json?.token) {
      setError(json.error)
    } else {
      setUser(json.user)
      setToken(json.token)
      navigation.navigate('main')
    }

    setBusy(false)
  }

  return (
    <View style={{ flex: 1 }}>
      <Text>{error}</Text>
      <Input placeholder="Email" onChangeText={event => setEmail(event)} />
      <Input placeholder="Password" secureTextEntry={true} onChangeText={event => setPassword(event)} />

      <Button status="info" onPress={submitLogin} disabled={!formComplete}>
        Submit
      </Button>
      <Link to="/login">
        <Text>Login</Text>
      </Link>
    </View>
  );
};

export default LoginPage;
