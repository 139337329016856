import * as React from 'react'
import { useResponsive } from '../../utils/responsive'
import PlaylistDesktop from './PlaylistDesktop'
import PlaylistMobile from './PlaylistMobile'

const VideoPage = props => {
  const { isDesktop } = useResponsive()

  if (isDesktop) {
    return <PlaylistDesktop {...props} />
  }

  if (!isDesktop) {
    return <PlaylistMobile {...props} />
  }
}

export default VideoPage
