import * as React from 'react';
import { useContext } from 'react';
import { StyleSheet, Image, View } from 'react-native';
import * as Device from 'expo-device';
import { Text, TopNavigation } from '@ui-kitten/components';
import { Link, useNavigationState } from '@react-navigation/native';
import { AppContext } from '../components/Context'
import { Icon } from '@ui-kitten/components';



const NavBar = () => {
  const { user, organization } = useContext(AppContext);
  const state = useNavigationState(state => state)
  let route

  try {
    route = state.routes[state.routes.length - 1].state.routes[state.routes.length - 1].params.screen

    if (!route) {
      route = state.routes[state.routes.length - 1].params.params.screen
    }

    if (!route) {
      route = state.routes[state.routes.length - 1].state.routes[state.routes.length - 1].state.routes[state.routes.length - 1].name
    }

  } catch (e) {
    route = 'browse'
  }

  const ProfileIcon = () => {
    return user ? (
      <View style={styles.row}>
        <Link to="/browse" style={{ textDecorationLine: 'none' }}>
          <Text style={[styles.link, route === 'browse' ? styles.linkActive : null]}>Browse</Text>
        </Link>
        <Link to="/favorites" style={{ textDecorationLine: 'none' }}>
          <Text style={[styles.link, route === 'favorites' ? styles.linkActive : null]}>Favorites</Text>
        </Link>
        <Link to="/challenges" style={{ textDecorationLine: 'none' }}>
          <Text style={[styles.link, route === 'challenges' ? styles.linkActive : null]}>Challenges</Text>
        </Link>
        <Link to="/shop" style={{ textDecorationLine: 'none' }}>
          <Text style={[styles.link, route === 'shop' ? styles.linkActive : null]}>Shop</Text>
        </Link>
        <Link to="/account" style={{ textDecorationLine: 'none' }}>
          <View style={{ marginLeft: 20, backgroundColor: '#000', width: 22, height: 22, borderRadius: 100, alignItems: 'center', justifyContent: 'flex-end' }}>
            <Icon
              name={'person'}
              color={'#fff'}
              size={22}
              style={{ height: 22, width: 22, marginBottom: -2.5 }}
              fill={'#fff'}
            />
          </View>
        </Link>
      </View>
    ) : (
        <Link to="/login" style={{ textDecorationLine: 'none' }}>
          <Text style={styles.link}>Login</Text>
        </Link>
      );
  };

  const BackIcon = () => (
    <Link to="/browse">
      <Image
        source={{
          uri: organization?.logo || 'http://png-2.vector.me/files/images/3/1/315456/simple_star_thumb',
        }}
        style={{
          height: 60,
          width: 400,
          resizeMode: 'contain'
        }}
      />
    </Link>
  );

  return (
    <TopNavigation
      accessoryLeft={BackIcon}
      accessoryRight={ProfileIcon}
      alignment="start"
      style={styles.bar}
    />
  );
};

export default NavBar;

const styles = StyleSheet.create({
  link: {
    color: '#8F9BB3',
    //fontWeight: 'bold',
    paddingHorizontal: 20,
  },
  linkActive: {
    color: '#AF4F31'
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  bar: {
    // borderColor: '#E4E9F2',
    // borderBottomWidth: 1,
  },
});
